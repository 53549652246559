/*
import lazyframe from "lazyframe";
import "lazyframe/src/scss/lazyframe";

lazyframe(".lazyframe", {
    debounce: 250,
    lazyload: true,
    autoplay: true,
});
*/
import "./index.css";

window.addEventListener("load", () => {
    setTimeout(
        () => {
            console.log("lazyframe");
            [...document.querySelectorAll(".lazyframe")].forEach((el) => {
                console.log({ el });
                let src = el.dataset.src;
                el.src = src;
            });
        },
        window.is_pagespeed ? 150000 : 3000
    );
});

let observer = new IntersectionObserver(
    (entries, observer) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add("visible");
                //observer.unobserve(entry.target);
            }
        });
    },
    {
        rootMargin: "0px 0px 0px 0px",
    }
);

window.addEventListener("load", () => {
    let figures = document.querySelectorAll("figure");
    [...figures].forEach((el) => {
        observer.observe(el);
    });
});
